import { AppDispatch } from "./store"

export enum ActionTypes {
  SET_VERSION_PREFERENCE = "SET_VERSION_PREFERENCE"
}

export type SetVersionPreference = {
  type: ActionTypes.SET_VERSION_PREFERENCE
  productName: string
  version: string
}

export type Actions = SetVersionPreference

const initialState = {
  versionPreferences: {}
}

export interface AppState {
  versionPreferences: {
    [key: string]: string | undefined
  }
}

export default function appReducer(state: AppState = initialState, action: Actions): AppState {
  switch (action.type) {
    case ActionTypes.SET_VERSION_PREFERENCE:
      return {
        ...state,
        versionPreferences: {
          ...state.versionPreferences,
          [action.productName]: action.version
        }
      }
      break
    // Do something here based on the different types of actions
  }
  return state
}

export function versionChangeHandler(dispatch: AppDispatch, productName: string) {
  return (newVersion: string) => {
    // Dispatch an action to update the version choice
    dispatch<SetVersionPreference>({
      type: ActionTypes.SET_VERSION_PREFERENCE,
      productName,
      version: newVersion
    });
  }
}