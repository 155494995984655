'use client'

import { ReactNode, useContext, useState, createContext, useTransition } from "react"
import { DrupalEntityWithJiraReference } from "@/types"
import { useQueryStates } from "nuqs"
import { searchParamsPreviewMode } from "@/lib/searchParams"

export interface LinkType {
  href: string
  title: string
  text: ReactNode
  className?: string
}

export type PreviewBarContextProps = {
  enabled: boolean
  preview: {
    mode?: 'revision' | 'diff' | 'current'
    modes: PreviewBarContextProps['preview']['mode'][] 
    state?: 'published' | 'draft' | 'archived' | 'code' | 'loading'
    links?: LinkType[]
    resource?: DrupalEntityWithJiraReference
  },
  set: {
    mode: (mode: PreviewBarContextProps["preview"]["mode"]) => void
    modes: (modes: PreviewBarContextProps["preview"]["mode"][]) => void
    state: (state: PreviewBarContextProps["preview"]["state"]) => void
    links: (links: PreviewBarContextProps["preview"]["links"]) => void
    resource: (resource: PreviewBarContextProps["preview"]["resource"]) => void
  },
  isLoading: boolean
  reset: () => void
}

export const modes:PreviewBarContextProps['preview']['mode'][] = ['revision', 'diff', 'current']

export const PreviewBarContext = createContext<PreviewBarContextProps>({
  enabled: false,
  preview: {
    mode: undefined,
    modes: [],
    state: undefined,
    links: undefined,
    resource: undefined,
  },
  set: {
    mode: () => {},
    modes: () => {},
    state: () => {},
    links: () => {},
    resource: () => {},
  },
  isLoading: false,
  reset: () => {}
})

export const usePreviewBar = () => useContext(PreviewBarContext);

export function PreviewBarProvider({ children, draftMode }: {
  draftMode: boolean
  children: ReactNode
}) {
  const [modes, setModes] = useState<PreviewBarContextProps["preview"]["modes"]>([])
  const [state, setState] = useState<PreviewBarContextProps["preview"]["state"]>()
  const [links, setLinks] = useState<PreviewBarContextProps["preview"]["links"]>()
  const [resource, setResource] = useState<PreviewBarContextProps["preview"]["resource"]>()
  const [isLoading, startTransition] = useTransition()
  const [params, setParams] = useQueryStates({
    ...searchParamsPreviewMode,
    previewMode: {
      ...searchParamsPreviewMode.previewMode,
      startTransition
    }
  })

  const reset = () => {
    // setParams({previewMode: undefined})
    setModes([])
    setState(undefined)
    setLinks(undefined)
    setResource(undefined)
  }

  return <PreviewBarContext.Provider value={{
      enabled: draftMode,
      preview: {mode: params.previewMode ?? undefined, modes, state, links, resource},
      set: {
        // mode: setMode, 
        mode: (mode: PreviewBarContextProps["preview"]["mode"]) => {
          startTransition(() => {
            setParams({ ...params, previewMode: mode }, { shallow: false })
          })
        },
        modes: setModes, 
        state: setState, 
        links: setLinks, 
        resource: setResource
      },
      isLoading,
      reset
    }}
    >
    {children}
    </PreviewBarContext.Provider>
}