'use client'
import classNames from "classnames"
import { Session } from "next-auth"
import { usePathname } from "next/navigation"
import { ReactNode, useEffect, useState } from "react"
import { PencilSquareIcon } from "@heroicons/react/24/outline"
import { usePreviewBar } from "@/contexts/PreviewBarProvider"

export const UserNav =  ({children = <></>, session, signOutLink = true}: {
  children?: ReactNode
  session: Session | null
  className?: string | string[] | null
  removeClassName?: string | string[] | null
  signOutLink?: boolean
}) => {
  const path = usePathname()
  const [inIframe, setInIframe] = useState(true)
  const { preview: { resource }  } = usePreviewBar()

  useEffect(() =>  {
    setInIframe(typeof window !== "undefined" && window.self != window.top)
  }, [])

  if (session === null || inIframe) {
    return <></>
  }

  return <div
    className='flex rounded border border-navy-600 p-1 gap-2'>
      { resource && <EditButton id={resource.drupal_internal__nid} />}
      { session?.user?.image !== undefined && session.user.image !== null && 
        withSignOutLink(signOutLink ? path : null, 
          <>
            {signOutLink === true && <span className="group-hover/avatar:after:bg-pink-600 absolute top-2 left-2 hidden group-hover/avatar:flex log-out-icon" />}
            <img className={classNames(signOutLink ? "group-hover/avatar:opacity-25" : "")} src={session.user.image} width={45} height={45} alt={session.user.name ?? 'user'} />
          </>
        )
      }
      {children}
    </div>
}

function withSignOutLink(redirect: string | null, children: ReactNode) {
  return typeof redirect === "string" ? (
    <a className="rounded bg-white overflow-hidden group/avatar relative" href={`/api/auth/signout?callbackUrl=${redirect}`} title="Sign out">
      {children}
    </a>
  ) : (
    <span className="rounded bg-white overflow-hidden group/avatar relative">
      {children}
    </span>
  )
}

const EditButton = ({id}: {
  id: number
}) => {
  return (
    <a href={`/api/edit?nid=${id}`} title="Edit content" className="flex transition-colors duration-300 items-center gap-2 p-2 rounded bg-gray-600/50 hover:bg-orange-600 hover:text-white">
      <span className="">Edit</span>
      <PencilSquareIcon className="w-4" />
    </a>
  )
}